import React from 'react';

function Testimonials() {
    return (
      <section className="testimonials py-5 text-center">
        <div className="container">
          <h2>O que nossos clientes dizem</h2>
          <div className="row">
            <div className="col-md-4">
              <blockquote>
                <p>"O Packman transformou a forma como gerenciamos as encomendas no condomínio. Tudo ficou mais rápido e organizado!"</p>
                <footer className="testimonial-footer">— Ana, Síndica</footer>
              </blockquote>
            </div>
            <div className="col-md-4">
              <blockquote>
                <p>"Agora sei exatamente quando minha encomenda chega. O sistema é muito intuitivo."</p>
                <footer className="testimonial-footer">— João, Morador</footer>
              </blockquote>
            </div>
            <div className="col-md-4">
              <blockquote>
                <p>"É muito mais fácil gerenciar as encomendas dos moradores com o Packman."</p>
                <footer className="testimonial-footer">— Marcos, Porteiro</footer>
              </blockquote>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Testimonials;
