import React from 'react';
import { ReactComponent as BellIcon } from 'bootstrap-icons/icons/bell-fill.svg';
import { ReactComponent as ArchiveIcon } from 'bootstrap-icons/icons/archive-fill.svg';
import { ReactComponent as PeopleIcon } from 'bootstrap-icons/icons/people-fill.svg';

function Features() {
  return (
    <section id="funcionalidades" className="features py-5 text-center">
      <div className="container">
        <h2 className="features-title">Funcionalidades do Packman</h2>
        <p className="features-subtitle">
          Veja como nosso sistema pode facilitar a gestão de encomendas no seu condomínio.
        </p>
        <div className="row">
          <div className="col-md-4 feature-card">
            <BellIcon width="48" height="48" className="feature-icon mb-3" />
            <h3 className="feature-title">Notificação de Encomendas</h3>
            <p className="feature-text">
              Os moradores recebem uma notificação em tempo real no aplicativo quando uma encomenda chega.
            </p>
          </div>
          <div className="col-md-4 feature-card">
            <ArchiveIcon width="48" height="48" className="feature-icon mb-3" />
            <h3 className="feature-title">Histórico de Entregas</h3>
            <p className="feature-text">
              Tenha acesso ao histórico completo de entregas e retiradas.
            </p>
          </div>
          <div className="col-md-4 feature-card">
            <PeopleIcon width="48" height="48" className="feature-icon mb-3" />
            <h3 className="feature-title">Gestão de Usuários</h3>
            <p className="feature-text">
              Controle as permissões e a associação de moradores ao condomínio.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
