import React from 'react';

function Contact() {
  return (
    <section className="contact-section">
      <h2>Entre em Contato</h2>
      <p>Preencha o formulário abaixo para entrar em contato conosco. Estamos aqui para ajudar!</p>
      
      <div className="form-container">
        <form>
          <div className="mb-3">
            <label htmlFor="name" className="form-label"></label>
            <input type="text" className="form-control" id="name" placeholder="Seu nome" />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label"></label>
            <input type="email" className="form-control" id="email" placeholder="Seu email" />
          </div>
          <div className="mb-3">
            <label htmlFor="subject" className="form-label"></label>
            <input type="text" className="form-control" id="subject" placeholder="Assunto da mensagem" />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label"></label>
            <textarea className="form-control" id="message" rows="5" placeholder="Escreva sua mensagem aqui..."></textarea>
          </div>
          <button type="submit" className="contact-submit-btn">Enviar Mensagem</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
