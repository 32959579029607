import React from 'react';
import { Button } from 'react-bootstrap';

function Hero() {
  const handleScroll = () => {
    const section = document.getElementById('funcionalidades');
    if (section) {
      const yOffset = -100; // Ajuste o valor para rolar menos (100 pixels acima)
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <section className="hero d-flex flex-column align-items-center text-center">
      <div className="container">
        <div className="hero-card">
          <h1 className="hero-title display-3 mb-4">Simplifique a entrega,<br /> automatize a gestão.</h1>
          <p className="hero-description lead mb-5">
            Packman é o sistema de gestão de encomendas para condomínios que facilita
            a administração e oferece uma experiência eficiente para moradores e administradores.
          </p>
          <Button variant="primary" size="lg" onClick={handleScroll} className="hero-btn">
            Saiba Mais
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
