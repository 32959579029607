import React from 'react';
import { ReactComponent as ClockIcon } from 'bootstrap-icons/icons/alarm-fill.svg';
import { ReactComponent as ClipboardIcon } from 'bootstrap-icons/icons/clipboard-check-fill.svg';
import { ReactComponent as BellIcon } from 'bootstrap-icons/icons/bell-fill.svg';

function Benefits() {
  return (
    <section className="benefits py-5 text-center">
      <div className="container">
        <h2 className="benefits-title">Por que escolher o Packman?</h2>
        <p className="benefits-subtitle">Entenda os principais motivos que fazem o Packman a escolha ideal para a gestão de encomendas em condomínios.</p>
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="benefit-card">
              <ClockIcon className="benefit-icon mb-3" width="40" height="40" />
              <h3>Redução de tempo</h3>
              <p>No processo de entrega, agilizando toda a logística.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="benefit-card">
              <ClipboardIcon className="benefit-icon mb-3" width="40" height="40" />
              <h3>Maior controle</h3>
              <p>Organização e monitoramento de encomendas em um só lugar.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="benefit-card">
              <BellIcon className="benefit-icon mb-3" width="40" height="40" />
              <h3>Notificações automáticas</h3>
              <p>Receba alertas e mantenha um histórico detalhado.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
