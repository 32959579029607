import React from 'react';
import { Button } from 'react-bootstrap';

function CallToAction() {
    return (
      <section className="cta d-flex flex-column align-items-center text-center">
        <div className="container">
          <div className="cta-card">
            <h2 className="cta-title mb-3">Pronto para transformar a gestão de encomendas do seu condomínio?</h2>
            <p className="cta-subtitle mb-4">Solicite uma demonstração e veja como o Packman pode fazer a diferença.</p>
            <Button 
              variant="primary" 
              href="/contato" 
              className="cta-btn px-4 py-2"
            >
              Solicitar Demonstração
            </Button>
          </div>
        </div>
      </section>
    );
}

export default CallToAction;
