import React from 'react';

function About() {
  return (
    <div className="container">
      <h1>Bem-vindo ao Packman!</h1>
      <p>Página de sobre nós</p>
    </div>
  );
}

export default About;
