import React from 'react';

function PrivacyPolicy() {
  return (
    <section className="privacy-policy-section">
      <div className="privacy-policy-container">
        <h1 className="mb-4">Política de Privacidade</h1>

        <p>Bem-vindo à nossa Política de Privacidade. A sua privacidade é importante para nós. Esta política descreve como coletamos, usamos e protegemos suas informações.</p>

        <h2 className="mt-5">1. Coleta de Informações</h2>
        <p>Coletamos informações pessoais que você nos fornece diretamente, como seu nome, e-mail e informações de contato. Além disso, coletamos dados automaticamente, como seu endereço IP, tipo de dispositivo, e informações sobre o uso do nosso site.</p>

        <h2 className="mt-5">2. Uso de Informações</h2>
        <p>Usamos as informações coletadas para fornecer, operar e melhorar nossos serviços, responder às suas solicitações e personalizar sua experiência. Podemos também usar seus dados para fins de comunicação, incluindo o envio de atualizações, newsletters e promoções.</p>

        <h2 className="mt-5">3. WhatsApp Business</h2>
        <p>Utilizamos o WhatsApp Business para comunicação com nossos clientes. Ao optar por se comunicar conosco via WhatsApp, você concorda que poderemos coletar informações sobre suas interações para melhorar o atendimento e personalizar sua experiência.</p>
        <p>Conforme exigido pelo WhatsApp Business, nos comprometemos a:</p>
        <ul>
          <li>Respeitar a privacidade e confidencialidade das suas conversas.</li>
          <li>Não compartilhar suas informações pessoais com terceiros sem o seu consentimento.</li>
          <li>Fornecer a você a possibilidade de optar por não receber mensagens através do WhatsApp a qualquer momento.</li>
        </ul>

        <h2 className="mt-5">4. Compartilhamento de Informações</h2>
        <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para fornecer nossos serviços ou quando exigido por lei. Caso precisemos compartilhar suas informações com parceiros ou prestadores de serviço, garantimos que estes estarão sujeitos a obrigações de confidencialidade e segurança.</p>

        <h2 className="mt-5">5. Segurança dos Dados</h2>
        <p>Adotamos medidas técnicas e organizacionais para proteger suas informações pessoais contra acesso, uso ou divulgação não autorizados. No entanto, nenhum sistema de segurança é infalível, e não podemos garantir a segurança absoluta de seus dados.</p>

        <h2 className="mt-5">6. Seus Direitos</h2>
        <p>Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais. Caso deseje exercer esses direitos, entre em contato conosco.</p>

        <h2 className="mt-5">7. Contato</h2>
        <p>Se você tiver dúvidas sobre nossa Política de Privacidade ou sobre o uso de suas informações, entre em contato conosco pelo e-mail <a href="mailto:suporte@packman.com.br">suporte@packman.com.br</a>.</p>

        <h2 className="mt-5">8. Alterações a Esta Política</h2>
        <p>Podemos atualizar nossa Política de Privacidade periodicamente para refletir mudanças em nossos serviços ou nas leis aplicáveis. Recomendamos que você revise esta página regularmente para se manter informado sobre nossas práticas de privacidade.</p>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
