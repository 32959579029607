import React from 'react';
import { Button } from 'react-bootstrap';
import { FaWhatsapp } from 'react-icons/fa';

function QuickContact() {
    return (
      <section className="contact-whatsapp d-flex flex-column align-items-center text-center">
        <div className="container">
            <p className="contact-title mb-3">Tem alguma dúvida?</p>
            <Button 
              variant="success" 
              href="https://wa.me/5513991656716" // Substitua pelo número de contato correto
              className="whatsapp-btn px-4 py-2"
            >
              <FaWhatsapp className="me-2" />
              Fale com a gente no WhatsApp
            </Button>
          </div>
      </section>
    );
}

export default QuickContact;
