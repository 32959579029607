import React from 'react';
import logo from '../assets/packman-logo.png';

function Header() {
  return (
    <header className="header d-flex align-items-center justify-content-between px-3 py-2">
      {/* Logo e Título com Link para Home */}
      <a href="/" className="d-flex align-items-center text-decoration-none text-dark">
        <img src={logo} alt="Packman Logo" className="header-logo mr-2" />
        <h1 className="header-title mb-0">Packman</h1>
      </a>
      {/* Botão Área do Cliente */}
      <button className="btn btn-light area-cliente-btn">Área do Cliente</button>
    </header>
  );
}

export default Header;
