import React from 'react';
import Hero from './hero';
import Features from './features';
import Benefits from './benefits';
import Testimonials from './testimonials';
import CallToAction from './callToAction';
import QuickContact from './quickContact';

function Home() {
  return (
    <div>
      <Hero />
      <Features />
      <Benefits />
      <Testimonials />
      <CallToAction />
      <QuickContact />
    </div>
  );
}

export default Home;
