import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Features from './pages/features';
import Contact from './pages/contact';
import PrivacyPolicy from './pages/privacyPolicy'; // Importando a página de Política de Privacidade
import Header from './components/header';
import Footer from './components/footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/funcionalidades" element={<Features />} />
        <Route path="/contato" element={<Contact />} />
        <Route path="/politica_de_privacidade" element={<PrivacyPolicy />} /> {/* Rota da Política de Privacidade */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
