import React from 'react';

function Footer() {
  return (
    <footer className="footer text-center py-4 mt-4">
      <div className="container">
        <p>&copy; 2024 Packman. Todos os direitos reservados.</p>
        <p>
          <a href="/contato" className="footer-link" style={{ textDecoration: 'underline', marginRight: '10px' }}>
            Fale Conosco
          </a> 
          | 
          <a href="/politica_de_privacidade" className="footer-link" style={{ textDecoration: 'underline', marginLeft: '10px' }}>
            Política de Privacidade
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
